<template>
  <div class="content">
    <!-- 封面图 -->
    <div class="section1">
      <div class="toIndex" @click="toIndex"></div>
      <img src="../assets/imgs/teachersDay_pc/t1.jpg" mode="widthFix" />
    </div>
    <!-- 感恩有我，师生同庆 -->
    <div class="section2">
      <div class="tit">
        <div class="title">感恩有我，师生同庆</div>
        <div class="mark"></div>
      </div>
      <div class="swiper" @mouseover="over" @mouseout="out">
        <div class="lef">
          2019年9月10日，“祖国礼赞，师韵芳华”
          望子成龙教育集团庆祝第35个教师节主题
          活动成功举办！恰逢中华人民共和国成立
          70周年之际，望子成龙教育集团4000余名
          师生齐聚一堂同庆教师节、感恩祖国母亲，
          现场气氛热烈，满溢着感恩教师和感恩祖国 之情！
        </div>
        <div class="rig">
          <div class="rig-left">
            <img
              src="../assets/imgs/teachersDay_pc/p28.png"
              mode="widthFix"
              @click="prev"
            />
          </div>
          <div class="rig-cen">
            <div
              class="rig-cen-main"
              :style="ulWidth"
              :class="{ tran: noLast }"
            >
              <div
                class="rig-cen-main-item"
                v-for="item in list1"
                :key="item.id"
                :style="{ width: liWidth + '%' }"
              >
                <img :src="item.img" mode="widthFix" />
              </div>
            </div>
          </div>
          <div class="rig-right">
            <img
              src="../assets/imgs/teachersDay_pc/p27.png"
              mode="widthFix"
              @click="next"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻 -->
    <div class="section3">
      <div class="cover">
        <img src="../assets/imgs/teachersDay_pc/t7.jpg" mode="widthFix" />
      </div>
      <div class="description">
        莅临本次活动的领导还有：教育部原副部长、国家总督学顾问张天保先生；
        教育部国家教育发展研究中心专家咨询委员会副主任、中国教育发展战略学
        会副会长、北京师范大学博士生导师周满生教授；四川省政协副秘书长、
        民进四川省委专职副主任 赵川荣先生；成都市政协副主席杨建德先生；
        都江堰市政府副市长袁明先生；都江堰市政协副主席杨永奎先生；
        都江堰市教育局党组书记、局长李桦先生；民进四川省委宣传处处长钟潜女士；
        都江堰市胥家镇党委书记付岷涛女士、都江堰市胥家镇镇长程绍荣女士；
        都江堰市教育局副局长张守军先生、王忠先生及都江堰市教育局有关领导。
      </div>
    </div>
    <!-- 精彩活动 -->
    <div class="section4">
      <div class="tit">
        <div class="title">精彩活动视频</div>
        <div class="mark"></div>
      </div>
      <div class="video">
        <video
          poster="https://xx.wzclzx.com/4a53ccb3451a54d90c290321036ba577"
          controls="controls"
          src="https://xx.wzclzx.com/883abb770b0b3660bb43ceaa1be4409b"
        ></video>
      </div>
    </div>
    <!-- 领导讲话 -->
    <div class="section5">
      <div class="tit">
        <div class="title">董事长致辞 领导讲话</div>
        <div class="mark"></div>
      </div>
      <div class="wrap" @mouseover="pause" @mouseout="play">
        <div class="left">
          <div
            class="left-item"
            :key="index"
            v-for="(item, index) in list2"
            @mouseover="onClick(index)"
          >
            <div class="line"></div>
            <div class="num" :class="{ activeColor: currentIndex == index }">
              {{ item.num }}
            </div>
            <div class="titlet" :class="{ activeColor: currentIndex == index }">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="right">
          <transition-group name="flip-list">
            <div class="right-item" v-for="(item, index) in list4" :key="index">
              <img :src="item.cover" mode="widthFix" />
              <div class="detail">{{ item.content }}</div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="section6">
      <div class="long">
        <img :src="imgUrl" class="imgs" />
      </div>
      <div class="mask">望子成龙庆祝第三十五个教师节合影留念</div>
    </div>
    <!-- 联动轮播 -->
    <div class="section7">
      <div class="tit">
        <div class="title">家国同庆 筑梦飞翔</div>
        <div class="mark"></div>
      </div>
      <!-- 联动开始 -->
      <div class="box" @mouseover="over1" @mouseout="out1">
        <div class="lunbo" :style="ulWidth1" :class="{ tran1: noLast1 }">
          <!-- 轮播 -->
          <div
            class="box-item"
            v-for="(item, index) in list5"
            :key="item.id"
            :style="{ width: liWidth1 + '%' }"
          >
            <!-- 轮播图片 -->
            <img :src="item.img" mode="widthFix" />
            <!-- 轮播内容 -->
            <div class="explain">
              <div class="explainT">
                {{ item.explain }}
              </div>
              <div class="explainB">
                <div class="explainBT">{{ index }}/{{ list5.length - 2 }}</div>
                <div class="explainBB">
                  <div class="zuo an" @click="prev1"></div>
                  <div class="cen">
                    <div
                      class="cen-item"
                      v-for="(item, index) in 12"
                      :key="index"
                      :class="{ activeBg: current1 == index }"
                    ></div>
                  </div>
                  <div class="you an" @click="next1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 左边按钮 -->
        <div class="btnL comment" @click="prev1">
          <span></span>
        </div>
        <!-- 右边按钮 -->
        <div class="btnR comment" @click="next1">
          <span></span>
        </div>
      </div>
      <!-- 联动结束 -->
    </div>
    <!-- 底部开始 -->
    <div class="foot">
      <div class="ft">
        <div class="ft-lef">
          <img
            mode="widthFix"
            src="../assets/imgs/teachersDay_pc/f1.png"
            @click="toIndex"
          />
        </div>
        <div class="ft-cen">
          <div class="same">
            <div class="same-item" v-for="item in list6" :key="item.id">
              {{ item.title }}
            </div>
          </div>
          <div class="same">
            <div class="same-item" v-for="item in list7" :key="item.id">
              <a :href="item.url">{{ item.title }}</a>
            </div>
          </div>
          <div class="same">
            <div class="same-item" v-for="item in list8" :key="item.id">
              <a :href="item.url">{{ item.title }}</a>
            </div>
          </div>
        </div>
        <div class="ft-rig">
          <div class="ft-rigT">全国统一客服电话</div>
          <div class="ft-rigB">
            <span>400-838-2288</span>
          </div>
        </div>
      </div>
      <div class="section">
        <!-- 地址 -->
        <div class="sec4">
          <img src="../assets/imgs/teachersDay_pc/f6.png" />
          <div class="des1">
            四川省成都市华达商城17楼 联系电话：028-85565012
          </div>
        </div>
        <!-- 公司 -->
        <div class="sec3">
          <img src="../assets/imgs/teachersDay_pc/f5.png" />
          <div class="des">成都望子成龙外语培训学校</div>
        </div>
        <!-- 53客服 -->
        <div class="sec2">
          <img src="../assets/imgs/teachersDay_pc/f4.png" @click="contact" />
        </div>
        <!-- 微信 -->
        <div class="sec1">
          <img src="../assets/imgs/teachersDay_pc/f3.png" />
          <div class="dropdown">
            <img
              src="../assets/imgs/teachersDay_pc/f8.jpg"
              alt="望子成龙学校"
            />
          </div>
        </div>
      </div>
      <div class="fb">
        Copyright 2020 www.WZCLXX.com All Rights Reserved
        成都望子成龙外语培训学校 版权所有 总部地址：
        四川省成都市武侯区高升桥路2号瑞金广场1楼 联系电话： 028-85565012
        蜀ICP备10031431号
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      //感恩有我，师生同庆
      list1: [
        {
          id: 0,

          img: require("../assets/imgs/teachersDay_pc/t6.jpg"),
        },
        {
          id: 1,
          img: require("../assets/imgs/teachersDay_pc/t2.jpg"),
        },
        {
          id: 2,
          img: require("../assets/imgs/teachersDay_pc/t3.jpg"),
        },
        {
          id: 3,
          img: require("../assets/imgs/teachersDay_pc/t4.jpg"),
        },
        {
          id: 4,
          img: require("../assets/imgs/teachersDay_pc/t5.jpg"),
        },
        {
          id: 5,
          img: require("../assets/imgs/teachersDay_pc/t6.jpg"),
        },
        {
          id: 6,
          img: require("../assets/imgs/teachersDay_pc/t2.jpg"),
        },
      ],
      bar: {
        width: "0",
        transform: "translateX(0)",
      },
      current: 0,
      noLast: true,
      timer: null,
      // 董事长致辞模块
      list2: [
        {
          id: 0,
          num: "01",
          title: "董事长致辞",
        },
        {
          id: 1,
          num: "02",
          title: "袁明先生",
        },
        {
          id: 2,
          num: "03",
          title: "杨建德先生",
        },
        {
          id: 3,
          num: "04",
          title: "张天保先生",
        },
      ],
      list3: [
        {
          id: 0,
          cover: require("../assets/imgs/teachersDay_pc/t8.jpg"),
          content: `
						庆祝活动在望子成龙教育集团董事长、
						成都玉垒中学校长蒋杨斌的讲话中正
						式拉开帷幕。他代表望子成龙教育集
						团，向辛勤耕耘、无私奉献的全体教
						职员工致以节日的问候和崇高的敬意！
						向指导、关心集团发展的各级领导、
						社会各界人士表示衷心的感谢！百年
						大计，教育为本。教育大计，教师为本。
						他鼓励老师誓做追梦人 ，甘为“大先生”，
						为践行现代教育强国，实现民族伟大复兴
						而不懈努力！`,
        },
        {
          id: 1,
          cover: require("../assets/imgs/teachersDay_pc/t9.jpg"),
          content: `
						都江堰市人民政府副市长袁明先生，祝贺望子成龙
						教育集团旗下玉垒中学被评为都江堰市先进集体。`,
        },
        {
          id: 2,
          cover: require("../assets/imgs/teachersDay_pc/t10.jpg"),
          content: "成都市政协副主席杨建德先生讲话。",
        },
        {
          id: 3,
          cover: require("../assets/imgs/teachersDay_pc/t11.jpg"),
          content: "原教育部副部长、国家总督学顾问张天保先生讲话",
        },
      ],
      //淡入淡出轮播图
      currentIndex: 0,
      list4: [],
      timer2: null,
      //联动轮播
      list5: [
        {
          id: 0,
          img: require("../assets/imgs/teachersDay_pc/b12.jpg"),
          explain: "全体合唱《歌唱祖国》",
        },
        {
          id: 1,
          img: require("../assets/imgs/teachersDay_pc/b1.jpg"),
          explain: `国家一级演员、首届中国音乐最高奖《金钟奖》获得者、成都市音乐家协会主席、东方茉莉女子国乐团团长、曾在今年的新春快闪《我和我的祖国》中感动中国的歌唱家马薇老师来到现场，为老师们献上了歌曲《我和我的祖国》和《好大一棵树》。`,
        },
        {
          id: 2,
          img: require("../assets/imgs/teachersDay_pc/b2.jpg"),
          explain: "汉式拜礼：20名教师+20名学生",
        },
        {
          id: 3,
          img: require("../assets/imgs/teachersDay_pc/b3.jpg"),
          explain: "敬献鲜花：学生代表向40名老师代表现场献花、敬礼",
        },
        {
          id: 4,
          img: require("../assets/imgs/teachersDay_pc/b4.jpg"),
          explain: "师德宣誓：台上的教师带领所有老师集体宣誓",
        },
        {
          id: 5,
          img: require("../assets/imgs/teachersDay_pc/b5.jpg"),
          explain: "手语舞蹈：《感恩老师》",
        },
        {
          id: 6,
          img: require("../assets/imgs/teachersDay_pc/b6.jpg"),
          explain: "快闪合唱：《我和我的祖国》",
        },
        {
          id: 7,
          img: require("../assets/imgs/teachersDay_pc/b7.jpg"),
          explain: "学生舞蹈：《老师，您好》",
        },
        {
          id: 8,
          img: require("../assets/imgs/teachersDay_pc/b8.jpg"),
          explain: "学生合唱：《亲爱的老师》",
        },
        {
          id: 9,
          img: require("../assets/imgs/teachersDay_pc/b9.jpg"),
          explain: "教师颂唱：《我爱你中国》",
        },
        {
          id: 10,
          img: require("../assets/imgs/teachersDay_pc/b10.jpg"),
          explain: "中华武术：《少年强则国强》",
        },
        {
          id: 11,
          img: require("../assets/imgs/teachersDay_pc/b11.jpg"),
          explain: "教师合唱：《爱我中华》（总校56个民族）",
        },
        {
          id: 12,
          img: require("../assets/imgs/teachersDay_pc/b12.jpg"),
          explain: "全体合唱：《歌唱祖国》",
        },
        {
          id: 13,
          img: require("../assets/imgs/teachersDay_pc/b4.jpg"),
          explain: "师德宣誓：台上的教师带领所有老师集体宣誓",
        },
      ],
      bar1: {
        width: "0",
        transform: "translateX(0)",
      },
      current1: 0,
      noLast1: true,
      timer1: null,
      //尾部
      list6: [
        {
          id: 0,
          title: "中国教育知名品牌",
        },

        {
          id: 1,
          title: "二十年品牌积淀",
        },
        {
          id: 2,
          title: "300万+注册学员",
        },
        {
          id: 3,
          title: "配置3000+专职名师",
        },
        {
          id: 4,
          title: "超过1000人服务团队",
        },
      ],
      list7: [
        {
          id: 0,
          title: "新闻资讯",
          url: "http://www.wzclxx.com/Wap/News/newList/cid/11.html",
        },

        {
          id: 1,
          title: "教育资讯",
          url: "http://www.wzclxx.com/Wap/News/newList/cid/12",
        },
        {
          id: 2,
          title: "学校新闻",
          url: "http://www.wzclxx.com/Wap/News/newList/cid/13",
        },
        {
          id: 3,
          title: "家长课堂",
          url: "http://www.wzclxx.com/Wap/News/newList/cid/14",
        },
        {
          id: 4,
          title: "学生讲堂",
          url: "http://www.wzclxx.com/Wap/News/newList/cid/133",
        },
      ],
      list8: [
        {
          id: 0,
          title: "关于我们",
          url: "#",
        },
        {
          id: 2,
          title: "学校介绍",
          url: "http://www.wzclxx.com/Wap/About/index.html",
        },
        {
          id: 3,
          title: "校区分布",
          url: "http://www.wzclxx.com/Wap/About/school.html",
        },
      ],
      //长图引入
      imgUrl: "https://xx.wzclzx.com/4ea6632e013fbfc30ea6ebacc5f3d566",
    };
  },
  mounted() {
    //淡入淡出轮播图
    this.list4 = [this.list3[0]];
    this.startChange();
  },
  onLoad() {
    this.autoSwitch();
    this.autoSwitch1();
  },
  computed: {
    ulWidth() {
      this.bar = {
        transform: "translateX(-" + (this.current + 1) * 100 + "%)",
      };
      return this.bar;
    },
    liWidth() {
      return 100;
    },
    ulWidth1() {
      this.bar1 = {
        transform: "translateX(-" + (this.current1 + 1) * 100 + "%)",
      };
      return this.bar1;
    },
    liWidth1() {
      return 100;
    },
  },
  methods: {
    //去首页
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
    //感恩有我，师生同庆
    //点击左侧按钮按钮事件
    prev() {
      this.current--;
      if (this.current == -1) {
        setTimeout(() => {
          this.noLast = false;
          this.current = this.list1.length - 3;
        }, 500); //定时器的时间等于过渡时间
      }
      this.noLast = true;
    },
    next() {
      this.current++;
      if (this.current == this.list1.length - 2) {
        setTimeout(() => {
          this.noLast = false;
          this.current = 0;
        }, 500);
      }
      this.noLast = true;
    },
    //自动播放
    autoSwitch() {
      this.timer = setInterval(() => {
        this.next();
      }, 3000);
    },
    //鼠标移入，暂停轮播
    over() {
      clearInterval(this.timer);
    },
    //鼠标移出，继续轮播
    out() {
      this.autoSwitch();
    },
    //董事长致辞讲话模块
    //淡入淡出轮播图
    startChange() {
      this.timer2 = setInterval(() => {
        if (this.currentIndex < this.list3.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
        this.list4.splice(0, 1, this.list3[this.currentIndex]);
      }, 2000);
    },
    //点击左侧按钮显示对应的图片
    onClick(index) {
      this.currentIndex = index;
      this.list4.splice(0, 1, this.list3[this.currentIndex]);
    },
    //鼠标移入暂停轮播
    pause() {
      clearInterval(this.timer2);
    },
    //鼠标移出继续轮播
    play() {
      this.startChange();
    },
    //联动轮播
    //点击左侧按钮按钮事件
    prev1() {
      this.current1--;
      if (this.current1 == -1) {
        setTimeout(() => {
          this.noLast1 = false;
          this.current1 = this.list5.length - 3;
        }, 500); //定时器的时间等于过渡时间
      }
      this.noLast1 = true;
    },
    next1() {
      this.current1++;
      if (this.current1 == this.list5.length - 2) {
        setTimeout(() => {
          this.noLast1 = false;
          this.current1 = 0;
        }, 500);
      }
      this.noLast1 = true;
    },
    //自动播放
    autoSwitch1() {
      this.timer1 = setInterval(() => {
        this.next1();
      }, 3000);
    },
    //鼠标移入，暂停轮播ss
    over1() {
      clearInterval(this.timer1);
    },
    //鼠标移出，继续轮播
    out1() {
      this.autoSwitch1();
    },
    //53客服
    contact() {
      location.href = "https://tb.53kf.com/code/client/10179248/1";
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  font-size: 14px;
}

//封面图
.section1 {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }

  .toIndex {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 99999;
  }

  .toIndex:hover {
    cursor: pointer;
  }
}

// 感恩有我师生同庆
.section2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tit {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 20px;
  margin-top: 50px;
  line-height: 50px;
}

.mark {
  width: 30px;
  height: 2px;
  background: #b13e37;
  transition: all 0.5s;
}

.tit:hover .mark {
  width: 80px;
}

.swiper {
  margin-top: 30px;
  width: 1200px;
  display: flex;
  flex-direction: row;
}

.lef {
  width: 300px;
  background: linear-gradient(to bottom, #e82718, #e13738);
  color: #ffffff;
  line-height: 45px;
  height: 496px;
  padding: 30px;
  box-sizing: border-box;
}

.rig {
  width: 900px;
  display: flex;
  align-items: center;
  position: relative;

  .rig-left {
    width: 40px;
    position: absolute;
    left: 10px;
    z-index: 9999;

    img {
      width: 40px;
    }

    img:hover {
      cursor: pointer;
    }
  }

  .rig-right {
    width: 40px;
    position: absolute;
    right: 10px;

    img {
      width: 40px;
    }

    img:hover {
      cursor: pointer;
    }
  }

  .rig-cen {
    width: 900px;
    height: 496px;
    overflow: hidden;
    position: relative;

    .rig-cen-main {
      display: -webkit-box;

      .rig-cen-main-item {
        width: 900px;
        height: 496px;

        img {
          width: 100%;
        }
      }
    }

    .tran {
      transition: all 0.5s;
    }
  }
}

//新闻
.section3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f4f4f4;
  height: auto;
  margin-top: 20px;

  flex-direction: column;

  .cover {
    width: 1200px;

    img {
      width: 100%;
    }
  }

  .description {
    width: 1200px;
    line-height: 45px;
    padding: 20px;
    box-sizing: border-box;
  }
}

//精彩活动视频
.section4 {
  width: 100%;
  height: auto;
  background: url(../assets/imgs/teachersDay_pc/tBg.jpg) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .video {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 1200px;

    video {
      width: 100%;
      height: 675px;
    }
  }
}

//领导讲话
.section5 {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f4f4f4;
  width: 100%;
  height: auto;

  .wrap {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin-top: 30px;
    margin-bottom: 70px;

    .left {
      width: 15%;

      .left-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 55px;

        .line {
          width: 1px;
          height: 40px;
          background: #666666;
        }

        .num {
          color: #898989;
          font-weight: bold;
          font-size: 32px;
        }

        .titlet {
          color: #898989;
          font-size: 14px;
        }

        .activeColor {
          color: #d7c097;
        }
      }

      .left-item:hover {
        cursor: pointer;
      }
    }

    .right {
      position: relative;
      width: 85%;

      .right-item {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        img {
          width: 100%;
        }

        .detail {
          bottom: 5px;
          width: 100%;
          height: 110px;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          color: #ffffff;
          text-align: left;
          line-height: 30px;
          padding: 10px 20px;
          font-size: 12px;
          box-sizing: border-box;
        }
      }
    }
  }
}

//淡入淡出轮播图
.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 1s;
}

.flip-list-enter,
.flip-list-leave-active {
  opacity: 0;
}


.section6 {
  width: 100%;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
  .long {
    display: flex;
    overflow-x: auto;
    animation: 80s rowup linear infinite normal;
    width: 4821px;
    img {
      width: 4821px;
      height: 342px;
      flex-shrink: 0;
    }
  }

  .mask {
    width: 100%;
    height: 45px;
    line-height: 45px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 16px;
    text-align: center;
    z-index: 10;
  }
}

@keyframes rowup {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-4821px));
  }
}

//家国同庆，筑梦飞翔
.section7 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;

  .box {
    width: 80%;
    height: auto;
    position: relative;
    margin: 40px auto 80px;

    .lunbo {
      width: 100%;
      display: inline-block;
      display: -webkit-box;
      height: auto;
      position: relative;

      //图片轮播
      .box-item {
        width: 100%;
        border: 10px solid #ffffff;
        border-top: 0px;
        border-bottom: 0px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;

        img {
          width: 100%;
        }

        //描述
        .explain {
          position: absolute;
          right: 10%;
          bottom: 10%;
          width: 230px;
          height: 320px;

          .explainT {
            width: 230px;
            height: 200px;
            padding: 15px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.5);
            line-height: 24px;
            font-size: 12px;
            color: #d7c097;
          }

          .explainB {
            width: 230px;
            height: 120px;
            background: rgba(255, 255, 255, 0.5);

            .explainBT {
              line-height: 50px;
              text-align: center;
              font-weight: bold;
              font-size: 16px;
            }

            .explainBB {
              width: 90%;
              margin: 10px auto;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              .an {
                width: 13px;
                display: block;
                height: 16px;

                background: url(~@/assets/imgs/teachersDay_pc/tbtns.png);
              }

              .zuo {
                background-position: 0px 0px;
              }

              .cen {
                width: 180px;
                height: 1px;
                background: #888888;
                margin: 0 5px;
                display: flex;
                flex-direction: row;

                .cen-item {
                  width: 15px;
                  height: 5px;
                  margin-top: -2.5px;
                }

                .activeBg {
                  background: #201b19;
                }
              }

              .you {
                background-position: 10px 0px;
              }
            }
          }
        }
      }
    }

    //左右按钮
    .comment {
      position: absolute;
      bottom: 5%;
      width: 60px;
      height: 60px;
      background: rgba(23, 24, 27, 0.8);
      z-index: 999999;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: 20px;
        display: inline-block;
        height: 20px;
        border: 2px solid #ffffff;
        border-left: none;
        border-top: none;
        transform: rotateZ(-45deg);
      }
    }

    //左边按钮
    .btnL {
      left: -70px;

      span {
        transform: rotateZ(-225deg);
      }
    }

    .btnR {
      left: 10px;
    }
  }

  .tran1 {
    transition: all 0.5s;
  }
}

//尾部
.foot {
  background: #2d2d2d;
  border-top: #464646;
  width: 100%;
  height: 300px;

  .ft {
    width: 1200px;
    height: 180px;
    margin: 0 auto;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    .ft-lef {
      width: 16.6%;

      img {
        width: 133px;
        height: 39px;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .ft-cen {
      width: 66.6%;
      display: flex;
      flex-direction: row;

      .same {
        width: 25%;

        .same-item {
          font-size: 12px;
          line-height: 22px;
          color: rgb(152, 152, 152);

          a {
            color: rgb(152, 152, 152);
            span-decoration: none;
          }
        }

        .same-item:nth-child(1) {
          font-size: 14px;
          color: rgb(255, 255, 255);
          margin-bottom: 15px;

          a {
            color: rgb(255, 255, 255);
            span-decoration: none;
          }
        }

        .same-item a:hover {
          color: rgb(255, 255, 255);
        }

        .same-item:hover {
          color: rgb(255, 255, 255);
        }
      }
    }

    .ft-rig {
      width: 16.6%;

      .ft-rigT {
        font-size: 20px;
        color: rgb(152, 152, 152);
        line-height: 30px;
      }

      .ft-rigB {
        width: 200px;
        font-size: 26px;
        margin-top: 7px;
        padding-left: 20px;
        background: url(../assets/imgs/teachersDay_pc/f2.png) no-repeat left
          center;
        color: #989898;
        line-height: 38px;
        display: block;
        text-align: right;

        span {
          color: rgb(152, 152, 152);
          line-height: 38px;
          font-size: 26px;
        }
      }
    }
  }

  //按钮

  .section {
    display: flex;
    flex-direction: row;

    font-size: 12px;
    line-height: 32px;
    color: #989898;
    display: flex;
    flex-direction: row-reverse;

    //微信
    .sec1 {
      margin-right: 10px;
      position: relative;

      img {
        width: 32px;
        height: 32px;
      }

      .dropdown {
        position: absolute;
        padding: 5px;
        border-radius: 2px;
        top: -135px;
        left: -40px;
        display: none;

        img {
          width: 100px;
          height: 100px;
        }
      }
    }

    .sec1:hover .dropdown {
      display: block;
    }

    //53
    .sec2 {
      margin-right: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #232323;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 13px;
        margin: 0 8px;
      }
    }

    .sec2:hover {
      cursor: pointer;
    }

    //公司
    .sec3 {
      margin-right: 10px;
      background: #232323;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      transition: all 0.5;
      overflow: hidden;

      img {
        width: 32px;
        height: 32px;
        display: inline-block;
        float: left;
      }

      .des1 {
        width: 180px;
        padding-left: 42px;
        font-size: 12px;
        line-height: 32px;
        color: #989898;
        box-sizing: border-box;
      }
    }

    .sec3:hover {
      width: 220px;
      background: #232323;
    }

    //地址
    .sec4 {
      margin-right: 10px;
      background: #232323;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      transition: all 0.5s;
      overflow: hidden;
      margin-right: 20%;

      img {
        width: 32px;
        height: 32px;
        display: inline-block;
        float: left;
      }

      .des1 {
        width: 340px;
        padding-left: 42px;
        font-size: 12px;
        line-height: 32px;
        color: #989898;
        box-sizing: border-box;
      }
    }

    .sec4:hover {
      width: 360px;
      background: #232323;
    }
  }

  .fb {
    text-align: center;
    color: #838383;
    font-size: 12px;
    margin-top: 30px;
  }
}
//css3效果
</style>
